export const sectionList = [
  'marketoForm', //contains marketoForm
  'imageWithPath', //contains Images , Heading with Link.
  'contentListColumn', //contains column structure for list
  'headerWithParagraph', // Contains heading with paragraph,links, ordered,unordered list
  'paragraphWithButtonLinks', // contains paragraph with buttons and links
  'greyCardWithImageAndLinks', //contains column structure for list with grey background
  'titleWithImage', // contains Header along with image of the page
  'buttonsWithImage', // contain buttons,heading with images
  'imageWithLinkWithSVG', // contains image at center, link heading, Paragraph, links, ordered/unordered list
  'imageWithTextLinkCenterAligned', // contains image at center, heading, Paragraph, buttons, ordered/unordered list
  'faqs', // contains FAQs section
  'contentListColumnWithCenterHeading', //contains column structure for list with center heading
  'headerWithParagraphAndArrowLink', //Contains heading with paragraph,links with Arrow , ordered,unordered list
  'headerWithParagraphAndNumberLink', //Contains heading with paragraph,links with Number  , ordered,unordered list
  'slpImageWithTitle', //contains slp page's image and  title
  'richTextWithGreyCardRight', //Contains grey card which will be always placed on right side
  'greyCardRightContentList', // contains grey card list which will be right
  'multipleSVGLinksList', // contains SVG Links content list
  'columnListSVGLinks', // contains columns for svg links specific for why choose ultramar
  'imageWithTextLinkSVGRight', //contains Image,link and paragraph and will be on right side
  'imageWithTextLinksSVG', // contains images links, svg at center
  'contentListWithGreyBackground', // contains contentlist with grey background
  'faqsWithCenterHeader', //contains FAQ with center in heading
  'headerWithParagraphButton', // // Contains heading with paragraph,buttons, ordered,unordered list and Image
  'greyCardRight', //contains grey card that will float on right side
  'blueContentListColumn', //contains blue card from propane page
  'greyCardWithImageAndButton', //contains grey card with buttons
  'blueCardHeader', //contains heading of blue card
  'greyCardWithBlueContentList', // contains blue card contentlist with grey back ground
  'arrowListContentList', // Contains Arrow list
  'contentListWith3ItemsGreyBackground', //contains grey background contentlist with 3 items in row
  'contentListWith4ItemsGreyBackground', //contains grey background contentlist with 4 items in row
  'imageWithHeadingLink', // contains image with heading
  'headerWithTickLinkAndButton', //contains header tick link and buttons
  'imageWithLinkRight', //Contains image will be float on right side with path
  'blogCard', //Blog Cards List Details
  'richTextWithImageRight', //Content Section for Blogs
  'headerWithParagraphAndImageRight', //Contains heading,paragraph,link and image which is right aligned
  'bottomStickyNote', //Contains order fuel online sticky note
  //Stand Alone Page Sections
  'slpHeaderWithoutProductName', //SLP heder without product names
  'slpSVGWithParagraph', //Contains SVG image with heading and paragraph
  'slpTermsAndConditions', //Contains slp's terms and conditions
  'slpHeaderWithProductsName', //contains slp page header with product name image
  'slpFFHeaderImage', //contains slp fill free header image
  'slpUltramarOffers', //contains slp fill free ultramar offer component
  'slpFFFooter', //contains slp fill free footer
  'contentWithBlank', //Rendered empty body.
  'columnListWith3Items', // contains column structure for having 3 items of list in one row.
  'columnListWith2Items', // contains column structure for having 2 items of list in one row.
  'contentWithBody', // Content having margin and image with full width.
  'svgWithBackgroundImage', //Renders SVG
  'headerWithParagraphCenter', //same as headerWithParagraph with center align
  'slpMarketoForm', //contains marketo forms of SLP pages.
  'slpWelcomeTitle', //contains SLP welcome page title
  'slpWelcomeImage', // contains SLP welcome page image below title
  'slpStickyNote', //sticky note on slp pages.
  'contentListColumnBlog', //column structure for blog pages.
  'greyCardBlog', //grey card for blog pages.
  'columnListWith4Items', //Row with four section if less than 4 present then leave that space empty example for-home->propane-equipment->our distributors
  'faqsInLine', //faq section to render 2 faq side-by-side example halifax-heating-oil page
  'slpCenterRichText', //all content at center for slp example slp/ff/thank-you
  'greyImageWithTextLinkSVGRight', //right column for our-history 2017 section
  'greyContent', //content with grey background
  'imageFullWidthCenterText', //content with image as width 100% height auto and text align center with links
  'imageExactWidthCenterText', //content with image as max-width fit-content height auto and text align center with links
  'sectionListSLP', //section list for SLP
  'slpAAImageParagraph', // slp AA image and paragraph component
  'richTextImageParagraph', // contains rich text with image with medium height
  'contentWithBodyLeftAlign', //same as contentWithBody but alignment of text is left
  'slpHeaderWithTranslation', //for slp crp header with en & fr
  'slpCRPImageWithText', //for slp crp image with yellow text
  'slpCRPFooter', //for slp crp footer
  'slpCRPRichText', //for slp crp section with yellow box
  'slpCRPListHeaderWithParagraph', //for slp crp list data
  'slpCRPContentList', //for slp crp list to render list data
  'slpSFOImage', //contains SLP SFO page title Image
  'searchSection', //component to handle search logic at ui side
  'slpBlueBackgroundImage', // contains blue background for slp pages with image
  'slpContact', // contains slp contact page
  'slpFFFOffers', // contains slp slp fff offer component
  'iframeContainer', //iframe for careers page
];

export const FormTypes = ['marketoForm', 'slpMarketoForm', 'searchSection'];
