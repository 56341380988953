import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
export const ModalBlocker = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 9999999;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  ::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.05em;
  }
`;

export const LangModal = styled.div`
  padding: 40px;
  background: rgb(0, 166, 232) !important; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  ) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  ) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  text-align: center !important;
  border-radius: 6px;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: auto;
`;
export const ModalImage = styled.img`
  display: block;
  width: 125px;
  height: auto;
  margin: -90px auto 40px auto;
`;
export const LanguageEnglish = styled.a`
  display: inline-block;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  padding-right: 35px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  &.active {
    color: #f7991c;
    span {
      color: #f7991c;
    }
  }
  :hover {
    color: #f7991c;
    span {
      color: #f7991c;
    }
  }
`;
export const LanguageFrench = styled.a`
  display: inline-block;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  padding-left: 25px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  &.active {
    color: #f7991c;
    span {
      color: #f7991c;
    }
  }
  :hover {
    color: #f7991c;
    span {
      color: #f7991c;
    }
  }
`;
export const LanguageSpan = styled.span`
  display: block;
  margin-bottom: 15px;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 60px;
  line-height: 60px;
  color: #fff;
  transition: all 0.25s ease-out;
  :hover {
    color: #f7991c;
  }
  :active {
    color: #f7991c;
  }
`;

export const LanguageRegionPara = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
`;

export const LanguageRegionLink = styled.a`
  display: block;
  padding: 7px 0;
  font-size: 18px;
  line-height: 26px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-decoration: none;
  color: #00a6e8;
  cursor: pointer;
  :hover {
    color: #f7991c;
  }
`;

export const LanguageRegion = styled.div`
  display: block;
  margin: 30px 0 0 0;
  transition: all 0.25s ease-out;
  ${LanguageRegionLink}:last-child {
    border-bottom: none;
  }
`;

export const ClearFix = styled.div`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;
export const ModalStyle = styled(Modal)`
  .modal-content {
    background-color: transparent;
    border: none;
    max-width: 400px;
    margin: 0 auto;
  }
`;
