import React, { useState } from 'react';
import * as Styled from './languageModalStyles';
import Cookies from 'universal-cookie';
import useRegionLanguage from '../../hooks/useRegionLanguage';
import UltramarLogo from '../../images/Ultramar-Logo.png';

const LanguageModal = () => {
  const data = useRegionLanguage();
  const [selectEnglish, setSelectEnglish] = useState(false);
  const [selectFrench, setSelectFrench] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const handleEnglish = () => {
    setSelectEnglish(true);
    setSelectFrench(false);
    setSelectedLanguage('en-US');
  };
  const handleFrench = () => {
    setSelectEnglish(false);
    setSelectFrench(true);
    setSelectedLanguage('fr-CA');
  };
  const regionList = data?.allContentfulUltramarCommercial?.edges.filter(
    (node) => {
      return node?.node?.node_locale === selectedLanguage;
    }
  );
  const cookies = new Cookies();
  const onChange = (regionName, language) => {
    const date = new Date();
    cookies.set('region', regionName?.toLowerCase(), {
      path: '/',
      expires: new Date(
        date.getFullYear() + 1,
        date.getMonth(),
        date.getDate()
      ),
    });
    cookies.set('language', language, {
      path: '/',
      expires: new Date(
        date.getFullYear() + 1,
        date.getMonth(),
        date.getDate()
      ),
    });

    if (selectFrench) {
      window.location.href = window?.location?.href?.replace('en', 'fr');
    } else {
      window.location.href = window?.location?.href?.replace('fr', 'en');
    }
  };
  React.useEffect(() => {
    cookies.get('region')?.toUpperCase()
      ? setShowModal(false)
      : setShowModal(true);
  }, [showModal]);
  return (
    <>
      <Styled.ModalStyle
        show={showModal}
        centered
        backdrop="static"
        backdropClassName="modal-backdrop-custom"
        data-aos="fade-up"
        data-aos-duration="700"
      >
        <Styled.LangModal>
          <Styled.ModalImage
            src={UltramarLogo}
            alt="Ultramar"
          ></Styled.ModalImage>
          <Styled.ClearFix>
            <Styled.LanguageEnglish
              onClick={() => handleEnglish()}
              className={selectEnglish ? 'active' : ''}
            >
              <Styled.LanguageSpan>EN</Styled.LanguageSpan>
              English
            </Styled.LanguageEnglish>
            <Styled.LanguageFrench
              onClick={() => handleFrench()}
              className={selectFrench ? 'active' : ''}
            >
              <Styled.LanguageSpan>FR</Styled.LanguageSpan>
              Français
            </Styled.LanguageFrench>
          </Styled.ClearFix>
          {(selectEnglish || selectFrench) && (
            <Styled.LanguageRegion data-aos="fade-up" data-aos-duration="700">
              <Styled.LanguageRegionPara>
                {selectEnglish
                  ? 'To give you access to the most relevant content please select your region.'
                  : 'Pour vous donner accès au contenu le plus pertinent, veuillez sélectionner votre région.'}
              </Styled.LanguageRegionPara>
              {regionList[0]?.node?.sections?.map((item, index) => (
                <Styled.LanguageRegionLink
                  key={index}
                  onClick={() =>
                    onChange(
                      item.abbreviation,
                      regionList[0]?.node?.node_locale
                    )
                  }
                >
                  {item.title}
                </Styled.LanguageRegionLink>
              ))}
            </Styled.LanguageRegion>
          )}
        </Styled.LangModal>
      </Styled.ModalStyle>
    </>
  );
};

export default LanguageModal;
